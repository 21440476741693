import request from '@/utils/request'

const payApi = {
    orderstatus:'/api/auth/orderstatus',
    member: '/api/auth/buymember',
    coupon:'/api/auth/buyCoupon',
    tpl:'/api/auth/buytpl',
    getworkno :'/api/auth/getworkno' //根据订单查作品
    

}

export default payApi

export function getOrderstatus(parameter) {
    return request({
        url: payApi.orderstatus,
        method: 'get',
        params: parameter
    })
}

export function payMember(parameter) {
    return request({
        url: payApi.member,
        method: 'post',
        data: parameter
    })
}

export function payCoupon(parameter) {
    return request({
        url: payApi.coupon,
        method: 'post',
        data: parameter
    })
}

export function payTpl(parameter) {
    return request({
        url: payApi.tpl,
        method: 'post',
        data: parameter
    })
}

export function getworkno(parameter) {
    return request({
        url: payApi.getworkno,
        method: 'get',
        params: parameter
    })
}




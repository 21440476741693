<template>
  <div class="preview-box">
      <perviewdetail :prodid="record.prodid" :tcode="record.tcode" :name="record.name" :psid="record.psid"/>
    <!-- <div class="preview-foot">
      <a-button
        type="primary"
        size="large"
        class="preview-btn"
        @click="showDetails"
      >
        查看详情
      </a-button>
    </div> -->
  </div>
</template>

<script>
import perviewdetail from './perviewdetail.vue'
export default {
  data() {
    return {};
  },
  components:{
      perviewdetail
  },
  methods: {
    showDetails() {
      //   this.$router.push({path: '/store/details',query:{tcode:this.record.tcode}})
      this.$router.push({
        path: "/store/details/" + this.record.tcode,
      });
    },
    onCancel() {
      console.log("监听了 modal cancel 事件");
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
  props: ["record"],
};
</script>

<style lang="less" scoped>
.preview-box {
  width: 420px;
  height: 700px;
  margin: 0 auto;
  padding: 20px 0 0px 0;
  .preview-header {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    margin-top: 10px;
    i {
      font-size: 14px;
      color: #999;
      cursor: pointer;
    }
  }
  .preview-foot {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .preview-btn {
      width: 330px;
    }
  }
}
</style>
<template>
   <div class="preview-body">
     <div class="pagination_box" v-if="this.$route.name != 'productdetail'">
           <div class="pagination_contain">
              <div class="pagination_left" @click="hanleSetMessage('prev')"  v-if="prodid === 2"><a-icon type="up" />上一页</div>
            <div class="pagination_right" @click="hanleSetMessage('next')" v-if="prodid === 2">下一页<a-icon type="down" /></div>
           </div>
          </div>
      <div class="phone_box">
       <iframe class="iframe"  ref="iframe" frameborder="0" :src="url +(prodid===1?'hb/preview/':prodid===2?'h5/showtemplates':'form/prew') + '?tcode='+tcode+'&preview=true'" scrolling="yes" id="myiframe"></iframe>
          <!-- <img src="@/assets/img/phone.png" alt="" /> -->
           <div class="phone_footer_detail" v-if="this.$route.name === 'productdetail'">
          <div class="pagination_box_detail" >
            <div class="pagination_left_detail" @click="hanleSetMessage('prev')" v-if="prodid === 2"><a-icon type="left" />上一页</div>
            <div class="pagination_right_detail" @click="hanleSetMessage('next')" v-if="prodid === 2">下一页<a-icon type="right" /></div>
               <div class="like_box_detail" @click="handleCollection" :class="isfav == 0?'action':''">
              <a-icon type="heart" />
              收藏
            </div>
          </div>
      </div>
      </div>
<div class="phone_detail" v-if="this.$route.name != 'productdetail'">
        <div class="phone_footer">
          <div class="code_box">
           <a-icon type="barcode" />
  <div class="code">  <vue-qr :text="downloadData.url" :margin="0" :logoSrc='logoSrc' colorLight="#fff" :size="120"></vue-qr>
  <h4>扫码预览效果</h4></div>
</div>
            <div class="like_box" @click="handleCollection" :class="isfav == 0?'action':''">
              <a-icon type="heart" />
            </div>
      </div>
</div>
    </div>
</template>
<script>
import vueQr from 'vue-qr'
import { collecttpl,getTplinfo} from '@/api/home.js'
import { mapGetters } from "vuex";
import LoginModal from "@/components/LoginModal";
export default {
  data(){
    return{
      url: process.env.VUE_APP_DESIGNER+'xedesign/',
      psid:'',
      downloadData: {
                url: process.env.VUE_APP_DESIGNER+'xedesign/' +(this.prodid===1?'hb/preview/':this.prodid===2?'h5/showtemplates':'form/prew') + '?tcode='+this.tcode,
            },
            isfav:1,
            logoSrc:require("../../assets/icon.svg")
    }
  },
  components:{
    vueQr
  },
  computed:{
     ...mapGetters(["member"])
  },
  methods:{
    hanleSetMessage(type){
      this.$refs.iframe.contentWindow.postMessage(type,'*')
      // console.log(this.url +(this.prodid===1?'hb/':this.prodid===2?'h5/':'form/') + 'work?workno='+this.tcode);
    },
    getTplList(){
      getTplinfo({tcode:this.tcode,xeucode:this.member.xeuCode}).then((res)=>{
     if(res.code === 200){
       console.log(res.data);
         this.isfav = res.data.isfav
         this.psid = res.data.psid
     }
      })
    },
    login() {
      let _this = this;
      _this.$dialog(
        LoginModal,
        // component props
        {
          record: {},
          on: {
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 360,
          centered: true,
          maskClosable: false,
          footer: "",
          bodyStyle: { padding: "0px", position: "relative" },
        }
      );
    },
handleCollection(){
  var fav = 1
  if(this.isfav == 0){
    fav = 1
  }else{
    fav = 0
  }
  let parameter = {
tcode:this.tcode,
isdelete:fav,
psid:this.psid
  }
collecttpl(parameter).then((res)=>{
  if(res.code === 200){
    this.isfav = fav
    console.log(this.isfav);
    this.$notification.open({
      message: '提示',
      description: res.msg
    })
  }else if(res.code === 401){
      //  console.log("2")
       this.login()
     }
  console.log(res);
})
},
ifrnameOnload(){
var _this = this
	const iframe = document.querySelector('#myiframe')
	// 处理兼容行问题
	if (iframe.attachEvent) {
		iframe.attachEvent('onload', function () {
      console.log('iframe已加载完毕')
			_this.completeLoad = true;
        })
    } else {
        iframe.onload = function () {
            console.log('iframe已加载完毕')
            _this.completeLoad = true;
        }
    }
},
  },
   props:["prodid","tcode","name"],
   mounted(){
    this.getTplList()
    this.ifrnameOnload()
   },
  watch:{
     $route:{
       handler:function(val){
         console.log(val);
       },deep:true,immediate:true
     }
  }
}
</script>

<style lang="less" scoped>
  .preview-body {
    text-align: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 10px;
    .phone_detail{
      flex: 1;
      position: relative;
      .text{
        margin: 20px 0;
       h2{
         font-weight: 700;
       }
      }
      .code_box{
        background-color:#ededed;
        padding: 3px 8px;
        border-radius: 6px;
        color:#333;
        font-size: 18px;
        margin-bottom: 20px;
        .code{
          display: none;
          position: absolute;
          top: 20px;
          left: 45px;
          background-color: #fff;
          width: 170px;
          height: 170px;
          padding: 10px 0;
          h4{
            font-size: 14px;
          }
        }
        h4{
          margin-top: 10px;
        }
      }
         .code_box:hover .code{
            display: block;
        }
    }
    .phone_footer{
        position: absolute;
        top: 50px;
        left: 10px;
        width: 90%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .phone_footer_detail{
      margin-top: 10px;
        .pagination_box_detail{
          display: flex;
          justify-content: space-around;
          align-items: center;
          .pagination_left_detail{
            cursor: pointer;
          }
            .pagination_right_detail{
            cursor: pointer;
          }
          .like_box_detail{
             cursor: pointer;
          background-color:#ededed;
        padding: 3px 8px;
        border-radius: 6px;
        color:#333;
        font-size: 16px;
          }
        }
    }
     .like_box{
          cursor: pointer;
          background-color:#ededed;
        padding: 3px 8px;
        border-radius: 6px;
        color:#333;
        font-size: 18px;
              }
              .action{
  color: red !important;
}
      .pagination_box {
        flex: 1;
        .pagination_contain{
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
        .pagination_left {
          cursor: pointer;
          width: 20px;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .pagination_right {
          cursor: pointer;
           width: 20px;
          line-height: 24px;
        }
        .pagination_text {
          margin: 0 10px;
        }
      }
    .phone_box {
      width: 320px;
      height: 630px;
      flex-shrink: 0;
    .iframe{
      width: 100%;
      height: 100%;
     box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
     border-radius: 4px;
    }
    }
  }
</style>
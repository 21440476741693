<template>
  <div class="product-item">
    <div class="product-content">
<a-skeleton :loading="loading"/>
      <div class="img">
          <img :src="ossurl + tavatar" class="tavatar" :id="'img'+tavatar" :data-src="ossurl + tavatar"/>
        <div class="drak">
          <img :src="ossurl + tavatar" />
        <div class="btn_group">
          <a-button
            type="primary"
            size="large"
            class="drak_btn"
            @click="showPreview"
            v-if="prodid!=1"
          >
            <a-icon type="search" />
            预览效果
          </a-button>
          <a-button
            type="primary"
            size="large"
            class="use_btn"
            @click="handleUse"
          >
            <a-icon type="check-square" />
            立即使用
          </a-button></div>
        </div>
      </div>
      <div class="content">
        <div class="titlebox">
          <div class="title">
            <span class="lable">{{ labelname }}</span
            >{{ name }}
          </div>
          <div class="tfname">
            <span v-for="item in tfnameList" :key="item">{{ item }}</span>
          </div>
        </div>
        <div class="marketbox">
          <a-row type="flex" justify="end">
            <a-col :span="12" class="price">
              <span v-if="price == 0.0">免费</span
              ><span v-else>￥{{ price }}</span>
            </a-col>
            <a-col :span="12" class="sales-volume">
              {{ usenumber }}人使用
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PostCreatePoster } from "@/api/works";
import LoginModal from "@/components/LoginModal";
import PerviewModal from "@/components/PerviewModal";
import { payTpl } from "@/api/pay";
export default {
  data() {
    return {
      tfnameList: [],
      loading:false
    };
  },
  methods: {
    change(){
      this.loading = false
      console.log(this.loading);
    },
       imgLoad(img, callback) {
            var timer = setInterval(function() {
                if (img.complete) {
                    callback(img)
                    clearInterval(timer)
                }
            }, 50)
       },
    handleUse() {
      if(this.prodid===1){
        this.CreatePoster()
      }else if(this.price==0.0){
        this.wxpay()
      }else {
        this.$router.push({ path: "/details/productdetail/" + this.tcode });
      }

    },
    wxpay() {

      // let _this = this;
      let parameter = {
        // xeucode:10086688,
        tcode: this.tcode,
        couponcode: "",
        paytype:2
      };

      payTpl(parameter)
        .then((data) => {
            if(data.code==200){
           this.openDesigner(data.data.workno,data.data.worktype)
          }else{
            this.login()
          }
        })
        .catch(() => {});
      return;
    },
    openDesigner(workno,worktype){

        let url;
        let host=process.env.VUE_APP_PCDESIGNER;
        // let host='https://wpc.xuan1tech.com/';
        switch(worktype) {

          case 1:
            url=host+'xedesign/hb/?workno='+workno
             break;
          case 2:
            url=host+'xedesign/h5/?workno='+workno
             break;
       case 3:
        url=host+'xedesign/form/?workno='+workno
         break;
       default:
      //  this.$util.msg("参数错误",{icon:'icon'})

     }
     console.log(url)
        // window.location.href = url;
        window.open(url);
    },
    CreatePoster() {
      PostCreatePoster({ tcode: this.tcode })
        .then((res) => {
          console.log(res);
          if(res.code==200){
            let host=process.env.VUE_APP_PCDESIGNER;
        // let host='https://wpc.xuan1tech.com/';
            window.open(host+'xedesign/hb/?workno='+res.data.workno);
          }else{
            this.login()
          }

          // this.$Router.push({
          //   path: "/pages/pageUser/designer/designer",
          //   query: { workno: res.workno, worktype: res.worktype },
          // });
        })
        .catch(() => {});
    },
    login() {
      let _this = this;
      _this.$dialog(
        LoginModal,
        // component props
        {
          record: {},
          on: {
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 360,
          centered: true,
          maskClosable: false,
          footer: "",
          bodyStyle: { padding: "0px", position: "relative" },
        }
      );
    },
    showPreview() {
      let _this = this;
      _this.$dialog(
        PerviewModal,
        // component props
        {
          record: { tcode: this.tcode, prodid: this.prodid, name: this.name,psid:this.psid},
          on: {
            ok() {
              _this.$router.push({
                path: "/details/productdetail/" + _this.tcode,
              });
            },
            cancel() {
              console.log("cancel 回调");
            },
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 420,
          centered: true,
          maskClosable: true,
          cancelButtonProps: { style: { display: "none" } },
          okButtonProps: {
            style: {
              position: "absolute",
              bottom: "10px",
              left: "40px",
              width: "320px",
              height: "40px",
            },
          },
          okText: "查看详情",
          wrapClassName: "previewmodal",
          bodyStyle: {
            padding: "0px",
            "background-color": "#f6f7fb",
            "border-radius": "8px",
          },
        }
      );
    },
  },
  mounted(){
    this.loading = true
    let _this = this
    var img = document.getElementById('img'+this.tavatar)
    this.imgLoad(img,function(){
          _this.loading = false
    })
  },
  computed: {
    ...mapGetters(["tplflagList"]),
  },
  props: [
    "name",
    "price",
    "usenumber",
    "labelname",
    "ossurl",
    "tavatar",
    "tcode",
    "tfids",
    "prodid",
    "psid"
  ],
  watch: {
    tfids: {
      handler(val) {
        this.tfnameList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.tplflagList.length; j++) {
            if (val[i] == this.tplflagList[j].tfid) {
              this.tfnameList.push(this.tplflagList[j].tfname);
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.product-item {
  background: #ffffff;
  box-shadow: 0px 5px 7px 0px rgba(236, 236, 236);
  border-radius: 4px;
  overflow: hidden;
  width: 260px;
  .product-content{
    width: 100%;
    position: relative;
  }
  .img {
    width: 100%;
    position: relative;
    overflow: hidden;
    .drak {
      display: none;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      .btn_group{
       position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        opacity: 0;
      }
      .drak_btn {
        width: 150px;
        height: 45px;
        border-radius: 5px;
        margin-bottom: 30px;
      }
      .use_btn {
        width: 150px;
        height: 45px;
        border-radius: 5px;
      }
    }
    img {
      width: 100%;
    }
  }
  .titlebox {
    margin-bottom: 8px;
    .tfname {
      margin-top: 10px;
      overflow-y: hidden;
      height: 27px;
      text-align: left;
      span {
        display: inline-block;
        border: 1px solid #fc592d;
        color: #fc592d;
        padding: 1px 3px;
        border-radius: 3px;
        font-size: 14px;
        margin-right: 8px;
        margin-bottom: 5px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      .lable {
        background: #5ad8a6;
        padding: 4px 7px 4px 6px;
        color: #fff;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        font-size: 14px;
        margin-right: 6px;
        font-weight: 300;
      }
    }
  }
  .content {
    padding: 16px 8px 26px 8px;
    .price {
      color: #ff592f;
      font-size: 16px;
      text-align: left;
    }
    .sales-volume {
      text-align: right;
      color: #989898;
      font-size: 16px;
    }
  }
}
.product-item:hover {
  box-shadow: 0px 5px 7px 0px rgb(187, 187, 187);
  .drak {
    display: block;
  }
}
</style>

<style lang="less">
.previewmodal {
  color: #5ad8a6;
  .ant-modal-footer {
    border: none;
    background-color: rgb(246, 247, 251);
  }
}
.ant-skeleton{
  position: absolute;
  top: 0;
  left: 0;
  height: 395px;
  background-color: #eef2f8;
  z-index: 999;
  .ant-skeleton-title{
    display: none;
  }
  .ant-skeleton-paragraph{
    display: none;
  }
}

</style>
import request from '@/utils/request'

const api = {
    hometplspecconfig: '/api/hometplspecconfig',
    gettplhotwords: 'api/gettplhotwords',
    getossurl: 'api/getossurl',
    showtplspec: 'api/showtplspec',
    getbannerlbist: 'api/banner',
    gettplinfo: 'api/tplinfo',
    collecttpl: '/api/auth/collecttpl',
    tplinfo: '/api/tplinfo'
}

export default api
export function getTplinfo(parameter) {
    return request({
        url: api.tplinfo,
        method: 'get',
        params: parameter
    })
}
export function collecttpl(parameter) {
    return request({
        url: api.collecttpl,
        method: 'post',
        data: parameter
    })
}
export function getHometplspecconfig(parameter) {
    return request({
        url: api.hometplspecconfig,
        method: 'get',
        params: parameter
    })
}
export function gettplinfo(parameter) {
    return request({
        url: api.gettplinfo,
        method: 'get',
        params: parameter
    })
}

export function getTplhotwords(parameter) {
    return request({
        url: api.gettplhotwords,
        method: 'get',
        params: parameter
    })
}
export function getossurl(parameter) {
    return request({
        url: api.getossurl,
        method: 'get',
        params: parameter
    })
}
export function showtplspec(parameter) {
    return request({
        url: api.showtplspec,
        method: 'get',
        params: parameter
    })
}
export function getBannerbList(parameter) {
    return request({
        url: api.getbannerlbist,
        method: 'get',
        params: parameter
    })
}